import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css';
import { ReactSession } from 'react-client-session';
import { useNavigate} from 'react-router-dom';

ReactSession.setStoreType("localStorage");

 function Paymentstatus() {

    const navigate = useNavigate();
    const [process, setProcess] = useState([]);
    const [preorderprocess, setpreorderProcess] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const orderId = queryParameters.get("id");


   const userId = ReactSession.get("arkuserId");
   const otpStatus = ReactSession.get("otp");
   if(userId > 0 && otpStatus == 'no'){
        navigate('/otp');
    }
    const fetchData = async () => {
        const formData = {id:orderId};
      
      try {
        const response = await axios.post(`https://api.arivomkadai.com/processsucess/`, formData);
        setProcess(response.data.data);
        //console.log(response.data);
      } catch (error) {
        
        console.error("Error fetching data:", error);
      }
    };


    useEffect(() => {
        fetchData();
    }, [orderId]); 



    // const fetchpreorderData = async () => {
      
    //     // console.log("user:",formData);
    //    try {
    //      const formData = {id:orderId};
    //      const response = await axios.post(`https://api.arivomkadai.com/proordersucess/`, formData);
  
    //      setpreorderProcess(response.data);
    //    console.log(response.data);
    //    } catch (error) {
         
    //      console.error("Error fetching data:", error);
    //    }
    //  };
    //  useEffect(() => {
    //     fetchpreorderData();
     
     
    //  }, []); 
  return (
    <>
  


 

  <main class="main">
       
        <div class="page-header mt-25 mb-10">
            <div class="container" style={{maxHeight:'100vh', marginTop: '5vh', marginLeft: 'auto', marginRight: 'auto'}}>
            


                          
                            
                <div class="archive-headers" >
                
                 <div class="row align-items-center">
                   
                   {process && process[0]?.payment_status  === "paid" ?
                   (<>
                     <div class="loop-grid loop-list pr-10 mb-50">
                            <article class="wow fadeIn animated hover-up mb-30 animated">
                                <div class="post-thumb  pl-50 pt-5" >
                                <h3 class="post-title mb-20">
                                <img src="assets/imgs/page/about-1.png" alt="" class="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4" style={{width:'50%',marginLeft:'auto', marginRight: 'auto', zIndex:'1'}}/><br></br>
                                        <a href='blog-post-right.html'>Thank you for your purchase</a>
                                    </h3>
                                    <p class="post-exerpt mb-40">Your order will be Processed Within 24 hours during working days. We will notify you by Whatsapp and <br></br> E-mail once your order has been shipped.</p>
                                    <div class="container col-md-12 mb-4 mb-md-0">
        <div class="contact-info">
            <div><b>Name:</b></div>
            <div class="data">{process && process[0]?.name}</div>
            
            <div><b>Address:</b></div>
            <div class="data">{process && process[0]?.buiulding} {process && process[0]?.street} {process && process[0]?.city} {process && process[0]?.state}</div>
            
            <div><b>Pincode:</b></div>
            <div class="data">{process && process[0]?.pincode}</div>
            
            <div><b>Phone:</b></div>
            <div class="data">{process && process[0]?.number}</div>
            
            {/* <div><b>Email:</b></div>
            <div class="data">{process && process[0]?.email}</div> */}
        </div>
    </div>
    </div>
                                <div class="entry-content-2">
                                
                                <div class="border p-10 cart-totals ml-30 mb-50" >
                        <div class="d-flex align-items-end justify-content-between mt-20 mb-30 ml-10">
                            <h4>Order Summary</h4>
                           
                        </div>
                        <div class="col-xl-12  d-none d-xl-block">
                            <ul class="tags-list">
                                <li class="hover-up">
                                    <p>Date<br></br>{process && process[0]?.date}</p>
                                </li>
                                <li class="hover-up">
                                    <p>Order ID<br></br>{process && process[0]?.order_id}</p>
                                </li>
                                <li class="hover-up">
                                    <p>Payment_Method<br></br>Online</p>
                                </li>
                                
                            </ul>
                        </div>
                        <div class="divider-2 mb-30"></div>
                        <div class="table-responsive order_table checkout no-border scrollable-table-container">
                        <div class="scrollable-table-content">
                            <table class="table no-border" width="100%">
                                <tbody>
                                {process && process[0]?.orderdetails && process.data.orderdetails.map(product => (
                                    <tr key={product.id}>
                                        <td width="15%" class="image product-thumbnail"><img src={`https://api.arivomkadai.com/product_data/${product.img}`} alt={product.product} width="100px" /></td>
                                        <td>
                                            <p class="mb-5"><a class='text-heading' href={`offlineproduct?id=${product.id}`}>{product.product.length > 50 ? `${product.product.slice(0, 50)}...` : product.product}</a></p>
                                            <p class="font-xxs ">{product.code} | {product.varient}</p>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                                <p class="font-sm">₹{product.price}</p>
                                                <p class="font-sm">x{product.quantity}</p>
                                                <p class="font-sm">₹{Number(product.price * product.quantity).toFixed(2)}</p>
                                            </div>
                                        </td>
                                    </tr>
                                  ))}
                                   
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td  class="cart_total_label" width="35%">
                                        <h6 class="text-muted ml-20">Sub Total</h6>
                                    </td>
                                    <td class="cart_total_amount">
                                        <h6 class="text-end">₹ {Number(process && process[0]?.total).toFixed(2)}</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td  class="cart_total_label" width="35%">
                                        <h6 class="text-muted ml-20">Delivery</h6>
                                    </td>
                                    <td class="cart_total_amount">
                                        <h6 class="text-end">₹ {Number(process && process[0]?.shipping).toFixed(2)}</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td  class="cart_total_label" width="35%">
                                        <h6 class="text-muted ml-20">Discount</h6>
                                    </td>
                                    <td class="cart_total_amount">
                                        <h6 class="text-end">- ₹ {Number(process && process[0]?.discount).toFixed(2)}</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td  class="cart_total_label" width="35%">
                                        <h6 class="text-muted ml-20">Grand Total</h6>
                                    </td>
                                    <td class="cart_total_amount">
                                        <h4 class="text-brand text-end">₹ {Number(process && process[0]?.grand_total).toFixed(2)}</h4>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                        
                    </div>
                    <a href={`/track?id=${orderId}`} class="btn mt-20 w-100">Track Order</a>
                    </div>
                   
                  
                                </div>
                               
                            </article>
                           
                            </div>   
                   </>)
                   :(<> 
                   
                   <div class="loop-grid loop-list pr-30 mb-50">
                            <article class="wow fadeIn animated hover-up mb-30 animated">
                                <div class="post-thumb" style={{textAlignLast: "center"}}> 
                                <h3 class="post-title mb-20" style={{textAlignLast: "center"}}>
                                <img src="assets/imgs/page/about-1.png" alt="" class="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4" style={{width:'50%',marginLeft:'auto', marginRight: 'auto', zIndex:'1'}}/><br></br>
                                        <a href='blog-post-right.html'>Unable to process your request.!</a>
                                    </h3>
                                    <p class="post-exerpt mb-40">Unable to verify your payment details. If you deduct any amount it will refund within 7 days. Thank you!</p>
                                    <a href='/offline' class="btn mt-20 w-10 mb-10">Home<i class="fi-rs-sign-out ml-15"></i></a>
    </div>
   
                               
                            </article>
                           
                            </div>
                     </>)}
                
                 
                 
                 
               
                 
                 </div>
             
               
                   
                </div>
            </div>
        </div>

       





        
    </main>

  

  </>
  )
}
export default Paymentstatus;
