import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './headerpages';
import { ReactSession } from 'react-client-session';
import { useNavigate } from "react-router-dom";
import Footeroffline from './footeroffline';
import ContentEditable from 'react-contenteditable';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Offlineproduct() {
    const navigate = useNavigate();
    if(!ReactSession.get("arkuserId")){
      ReactSession.set("arkuserId","0");
    }
    const userId = ReactSession.get("arkuserId");
    const otpStatus = ReactSession.get("otp");
    const [productData, setProductData] = useState(null);
    const [productCatData, setCatProductData] = useState(null);
    const [category, setCategory] = useState(null);
    const queryParameters = new URLSearchParams(window.location.search)
    const productId = queryParameters.get("id");
    const [quantity, setQuantity] = useState(1);
    const [stock, setStock]=useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedPrice, setSelectedPrice] = useState('');
    const [selectedMrp, setSelectedMrp] = useState('');
    const [selectedGst, setSelectedGst] = useState('');
    const [selectedWeight, setSelectedWeight] = useState('');
    const [selectedPurch, setSelectedPurch] = useState('');
    const [selectedWidth, setSelectedWidth] = useState('');
    const [selectedHeight, setSelectedHeight] = useState('');
    const [selectedShippingWeight, setSelectedShippingWeight] = useState('');
    const [selectedBreadth, setSelectedBreadth] = useState('');
    const [preProduct, setPreProduct] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
   

    // Function to handle click on size options
    const handleSizeClick = (size) => {
          setSelectedSize(size);
         const sizes = productData && productData.data[0].variations.filter(items => items.value=== size);
        // console.log(sizes);
        const  price= sizes[0].price;
        const  weight= sizes[0].weights;
        const  mrp= sizes[0].mrp;
        const  purch= sizes[0].p_price;
        const  Width= sizes[0].width;
        const  Height= sizes[0].height;
        const  SWeight= sizes[0].shippingweight;
        const  breadth= sizes[0].breadth;
        const  gst= sizes[0].gst;
        //console.log(price);
        setSelectedPrice(price);
        setSelectedMrp(mrp);
        setSelectedWeight(weight);
        setSelectedPurch(purch);
        setSelectedWidth(Width);
        setSelectedHeight(Height);
        setSelectedShippingWeight(SWeight);
        setSelectedBreadth(breadth);
        setSelectedGst(gst);
    };
    
    const openModal1 = (id) => {
      const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
  
      
      const existingItemIndex = existingCartItems.findIndex(item => item.id === id);
  
      if (existingItemIndex !== -1) {
        
          openModalsalready(); 
      } else {
        
          setSelectedId(id);
          setPreProduct([id]); 
  
          const modal = document.getElementById('copyModal1');
          if (modal) {
              modal.classList.add('show');
              modal.style.display = 'block';
              document.body.classList.add('modal-open');
          }
      }
  };
  
   


      const closeModal1 = () => {
        const modal = document.getElementById('copyModal1');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            document.body.classList.remove('modal-open');
        }
      };
      
      const contPreOrder = () => {
        addToCart(selectedId);
      };
    
    
   
     

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`https://api.arivomkadai.com/editofflineproduct/?id=${productId}`);
            setProductData(response.data);
            //console.log(response.data);
            if(response.data.data[0].variations[0].value){
                setSelectedSize(response.data.data[0].variations[0].value);
            }
            if(response.data.data[0].variations[0].price){
                setSelectedPrice(response.data.data[0].variations[0].price);
            }
            if(response.data.data[0].variations[0].mrp){
              setSelectedMrp(response.data.data[0].variations[0].mrp);
          }
            if(response.data.data[0].variations[0].weights){
                setSelectedWeight(response.data.data[0].variations[0].weights);
            }
            if(response.data.data[0].variations[0].p_price){
              setSelectedPurch(response.data.data[0].variations[0].p_price);
            }
            if(response.data.data[0].variations[0].width){
              setSelectedWidth(response.data.data[0].variations[0].width);
            }
            if(response.data.data[0].variations[0].height){
              setSelectedHeight(response.data.data[0].variations[0].height);
            }
            if(response.data.data[0].variations[0].shippingweight){
              setSelectedShippingWeight(response.data.data[0].variations[0].shippingweight);
            }
            if(response.data.data[0].variations[0].breadth){
              setSelectedBreadth(response.data.data[0].variations[0].breadth);
            }
            if(response.data.data[0].variations[0].gst){
              setSelectedGst(response.data.data[0].variations[0].gst);
            }
            const stockValue = response.data.data[0].stock;
            setStock(stockValue);
           // console.log(stockValue);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData();
      }, [productId]);

      useEffect(() => {
        if (productData && productData.data && productData.data.length > 0) {
          const categoryValue = productData.data[0].cat;
          setCategory(categoryValue);
        }
      }, [productData]);




      useEffect(() => {
        const fetchCatData = async () => {
          try {
            const response = await axios.get('https://api.arivomkadai.com/offlineproduct/', {limit: 'yes'}) ;
            setCatProductData(response.data);
           // console.log(response.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchCatData();
      }, [category]);

      
      const categories = productCatData && productCatData.data.filter(item => item.category === category); 

      const openModal = () => {
        const modal = document.getElementById('copyModal');
        if (modal) {
            modal.classList.add('show');
            modal.style.display = 'block';
            document.body.classList.add('modal-open');
        }
    };

    const closeModal = () => {
        const modal = document.getElementById('copyModal');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            document.body.classList.remove('modal-open');
        }
    };


    const openModalstock = () => {
      const modal = document.getElementById('copyModalstock');
     
      if (modal) {
          modal.classList.add('show');
          modal.style.display = 'block';
          document.body.classList.add('modal-open');
      }
  };

  const closeModalstock = () => {
      const modal = document.getElementById('copyModalstock');
      if (modal) {
          modal.classList.remove('show');
          modal.style.display = 'none';
          document.body.classList.remove('modal-open');
      }
  };

  const Continuestock = () => {
    addToCart(selectedId);
    closeModalstock();
  };

    const copyButtonClick = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
        alert('Link Copied to Clipboard.');
        const modal = document.getElementById('copyModal');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            document.body.classList.remove('modal-open');
        }
      };
 
      
    
      const handleQuantityChange = (event) => {
        const value = event.target.value;
    
        // Ensure the input value is a valid number
        const parsedValue = parseInt(value);
    
        // Check if parsedValue is a valid number
        if (!isNaN(parsedValue)) {
          
          if(parsedValue > stock){
            setQuantity(parsedValue);
            openModalstock(productData && productData.data[0].p_id);
          }else{
            // Update quantity state with the parsed integer value
            setQuantity(parsedValue);
          }
        } else {
            // Handle cases where the input value is not a valid number
            // For example, you could reset the quantity state or handle the error
            setQuantity(''); // Reset quantity to 0 or handle error accordingly
        }
    };
    
    
    

      const handleIncrement = () => {
        let newquantity =quantity;
        if(selectedWeight === 'counts'){
            newquantity = selectedSize*quantity;
        }
        if(selectedWeight === 'kg'){
            newquantity = selectedSize*quantity;
        }
        if(selectedWeight === 'l'){
            newquantity = selectedSize*quantity;
        }
        if(selectedWeight === 'ml'){
            newquantity = (selectedSize/1000)*quantity;
        }
        if(selectedWeight === 'g'){
            newquantity = (selectedSize/1000)*quantity;
        }


    //   if (newquantity <  stock) {
    //     setQuantity(prevCount => prevCount + 1);
    //   }
    //   else if(newquantity === stock){
        
    //     if (preProduct.includes(productId)) {
    //         setQuantity(prevCount => prevCount + 1);
    //     }else{
    //         openModalstock(productData && ( productData.data[0].p_id));
    //     }
    //   }else{
        
    //     if (preProduct.includes(productId)) {
    //         setQuantity(prevCount => prevCount + 1);
    //     }else{
    //         openModal1(productData && ( productData.data[0].p_id));
    //     }
    //   }
    // };

   
    if (newquantity < stock) {
      setQuantity(prevCount => prevCount + 1);
    } else if (newquantity == stock) {
      if (preProduct.includes(productId)) {
        setQuantity(prevCount => prevCount + 1);
      } else {
        openModalstock(productData && productData.data[0].p_id);
      }
    } else if (newquantity > stock) {
      if (preProduct.includes(productId)) {
        setQuantity(prevCount => prevCount + 1);
      } else {
        openModal1(productData && productData.data[0].p_id);
      }
    }
      };    
  
    const handleDecrement = () => {
      if (quantity > 1) {
        setQuantity(prevCount => prevCount - 1);
    
      }
    };
      
      
      const addTowish = async(event) => {
       
        if(userId > 0 && otpStatus === 'no'){
            navigate('/otp');
         }
        else{
        const wishitem = {
            cust_id: userId,
            id: productId,
            product: productData?.data[0]?.product,
            price: productData?.data[0]?.variations ? selectedPrice : productData?.data[0]?.price,
            image: productData?.data[0]?.image
        }
        try {
            const response = await axios.post(`https://api.arivomkadai.com/addwish/`, wishitem);
            const message = response.data.message;
            
            if(response.data.status === 'success'){
                alert(message);
            }
           
        } catch (error) {
            console.error('There was a problem with login:', error);
        }
       
    }  
      }

    //   const addToCart = () => {
    //     // Get the existing cart items from session storage
    //     const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
    
    //     // Check if the product is already in the cart
    //     const existingItemIndex = existingCartItems.findIndex(item => item.id === productId);
    
    //     if (existingItemIndex !== -1) {
    //         // If the product is already in the cart, update its quantity
    //         existingCartItems[existingItemIndex].quantity += quantity;
    //     } else {
    //         // If the product is not in the cart, add it as a new item
    //         const newItem = {
    //             id: productId,
    //             product: productData?.data[0]?.product,
    //             price: productData?.data[0]?.price,
    //             quantity: quantity,
    //             image: productData?.data[0]?.image
    //         };
    
    //         // Add the new item to the cart
    //         existingCartItems.push(newItem);
    //     }
    
    //     // Store the updated cart items back in session storage
    //     sessionStorage.setItem('cartItems', JSON.stringify(existingCartItems));
    //     alert(`${productData?.data[0]?.product} added to cart!`);
    // };
    

   
const openModals = () => {
    const modal = document.getElementById('copyModals');
    if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
    }
  };
  
  const closeModals = () => {
    const modal = document.getElementById('copyModals');
    if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
    }
  };

  const openModalsalready = () => {
    const modal = document.getElementById('copyModalsready');
    if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
      
    }
    
    
    
  };
  
  const closeModalsalready = () => {
    const modal = document.getElementById('copyModalsready');
    if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
    }
  };

    const [warning, setWarning] = useState('no');
  
    const handleYesClick = () => {
        setWarning('yes');
        sessionStorage.removeItem('preorder');
        closeModals(); // Close the modal after setting the warning
    };
    
    const handleNoClick = () => {
        setWarning('no');
        closeModals(); // Close the modal after setting the warning
    };



    const openmodalpreorder = () => {
      const modal = document.getElementById('copyModalspreorder');
      if (modal) {
          modal.classList.add('show');
          modal.style.display = 'block';
          document.body.classList.add('modal-open');
          
      }
      
    
      
    };
    
    const closeModalspreorder = () => {
      const modal = document.getElementById('copyModalspreorder');
      if (modal) {
          modal.classList.remove('show');
          modal.style.display = 'none';
          document.body.classList.remove('modal-open');
      }
    };
    
    
    useEffect(() => {
      const storedPreorderItem = JSON.parse(sessionStorage.getItem('preorder'));
      if(storedPreorderItem === null || storedPreorderItem.length === 0){
            setWarning('yes');
      }
    }, []);


    const addToCart = () => {
        if (warning === 'yes') {
            sessionStorage.removeItem('preorder');
            // Get the existing cart items from session storage
        const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
    
        // Check if the product is already in the cart
        const existingItemIndex = existingCartItems.findIndex(item => item.id === productId);
    
        if (existingItemIndex !== -1) {
            // Calculate the new quantity if the product is added to the existing quantity
            // const updatedQuantity = existingCartItems[existingItemIndex].quantity + quantity;
    
            // // Check if adding the product will exceed the available stock
            // if (updatedQuantity <= productData?.data[0]?.stock) {
            //     // If there's sufficient stock, update the quantity
            //     existingCartItems[existingItemIndex].quantity = updatedQuantity;
            // } else {
            //     // If there's insufficient stock, show an alert
            //     alert(`Insufficient stock for ${productData?.data[0]?.product}`);
            //     return; // Stop further execution
            // }
           //
           // alert(`${productData?.data[0]?.product} already in cart!`);
            openModalsalready();
        } else {
            let newquantity =quantity;
            if(selectedWeight === 'counts'){
                newquantity = selectedSize*quantity;
            }
            if(selectedWeight === 'kg'){
                newquantity = selectedSize*quantity;
            }
            if(selectedWeight === 'l'){
                newquantity = selectedSize*quantity;
            }
            if(selectedWeight === 'ml'){
                newquantity = (selectedSize/1000)*quantity;
            }
            if(selectedWeight === 'g'){
                newquantity = (selectedSize/1000)*quantity;
            }
            // Check if there's sufficient stock before adding the product as a new item
            if (productData?.data[0]?.stock >= newquantity) {
                // Add the product as a new item
                const newItem = {
                    id: productId,
                    code: productData?.data[0]?.product_code,
                    product: productData?.data[0]?.product,
                    size: selectedSize,
                    weight: selectedWeight,
                    quantity: quantity,
                    image: productData?.data[0]?.image,
                    price: productData?.data[0]?.variations ? selectedPrice : productData?.data[0]?.price,
                    mrp: productData?.data[0]?.variations ? selectedMrp : productData?.data[0]?.mrp,
                    purchase: productData?.data[0]?.variations ? selectedPurch : productData?.data[0]?.p_price,
                    //courier: productData?.data[0]?.variations ? selectedPrice : productData?.data[0]?.courier,
                    //deliverycharge: productData?.data[0]?.variations ? selectedPrice : productData?.data[0]?.deliveryCharge,
                    width: productData?.data[0]?.variations ? selectedWidth : productData?.data[0]?.width,
                    height: productData?.data[0]?.variations ? selectedHeight : productData?.data[0]?.height,
                    shippingweight: productData?.data[0]?.variations ? selectedShippingWeight : productData?.data[0]?.shippingweight,
                    breadth: productData?.data[0]?.variations ? selectedBreadth : productData?.data[0]?.breadth,
                    gst: productData?.data[0]?.variations ? selectedGst : productData?.data[0]?.gst,
                    preorder: 'no',
                    vendor:productData?.data[0]?.vendor,
                    vendorcomission:productData?.data[0]?.vendorcommission,
                    hsn:productData?.data[0]?.hsn,
                };
                
                existingCartItems.push(newItem);
                alert(`${productData?.data[0]?.product} added to cart!`);
            }else {
                if (preProduct.includes(productId)) {
                    if(productData?.data[0]?.preorder == '0'){
                      const newItem = {
                        id: productId,
                        code: productData?.data[0]?.product_code,
                        product: productData?.data[0]?.product,
                        size: selectedSize,
                        weight: selectedWeight,
                        quantity: quantity,
                        image: productData?.data[0]?.image,
                        price: productData?.data[0]?.variations ? selectedPrice : productData?.data[0]?.price,
                        mrp: productData?.data[0]?.variations ? selectedMrp : productData?.data[0]?.mrp,
                        purchase: productData?.data[0]?.variations ? selectedPurch : productData?.data[0]?.p_price,
                        //courier: productData?.data[0]?.variations ? selectedPrice : productData?.data[0]?.courier,
                        //deliverycharge: productData?.data[0]?.variations ? selectedPrice : productData?.data[0]?.deliveryCharge,
                        width: productData?.data[0]?.variations ? selectedWidth : productData?.data[0]?.width,
                        height: productData?.data[0]?.variations ? selectedHeight : productData?.data[0]?.height,
                        shippingweight: productData?.data[0]?.variations ? selectedShippingWeight : productData?.data[0]?.shippingweight,
                        breadth: productData?.data[0]?.variations ? selectedBreadth : productData?.data[0]?.breadth,
                        gst: productData?.data[0]?.variations ? selectedGst : productData?.data[0]?.gst,
                        preorder: 'yes',
                        vendor:productData?.data[0]?.vendor,
                        vendorcomission:productData?.data[0]?.vendorcommission,
                        hsn:productData?.data[0]?.hsn,
                   };
                    
                    existingCartItems.push(newItem);
                    closeModal1();
                    }else{
                      closeModal1();
                      openmodalpreorder();
                      
                    }
                    
                }else {
                  if(productData?.data[0]?.preorder == '0'){
                    const newItem = {
                      id: productId,
                      code: productData?.data[0]?.product_code,
                      product: productData?.data[0]?.product,
                      size: selectedSize,
                      weight: selectedWeight,
                      quantity: quantity,
                      image: productData?.data[0]?.image,
                      price: productData?.data[0]?.variations ? selectedPrice : productData?.data[0]?.price,
                      mrp: productData?.data[0]?.variations ? selectedMrp : productData?.data[0]?.mrp,
                      purchase: productData?.data[0]?.variations ? selectedPurch : productData?.data[0]?.p_price,
                      //courier: productData?.data[0]?.variations ? selectedPrice : productData?.data[0]?.courier,
                      //deliverycharge: productData?.data[0]?.variations ? selectedPrice : productData?.data[0]?.deliveryCharge,
                      width: productData?.data[0]?.variations ? selectedWidth : productData?.data[0]?.width,
                      height: productData?.data[0]?.variations ? selectedHeight : productData?.data[0]?.height,
                      shippingweight: productData?.data[0]?.variations ? selectedShippingWeight : productData?.data[0]?.shippingweight,
                      breadth: productData?.data[0]?.variations ? selectedBreadth : productData?.data[0]?.breadth,
                      gst: productData?.data[0]?.variations ? selectedGst : productData?.data[0]?.gst,
                      preorder: 'yes',
                      vendor: productData?.data[0]?.vendor,
                      vendorcomission: productData?.data[0]?.vendorcommission,
                      hsn: productData?.data[0]?.hsn,
                  };
                  existingCartItems.push(newItem);
                  alert(`${productData?.data[0]?.product} added to cart!`);
                  }else{
                    closeModal1();
                    openmodalpreorder();
                    
                  }
                 
               
                }
            }
            sessionStorage.setItem('cartItems', JSON.stringify(existingCartItems));
            
        }
    
        // Store the updated cart items back in session storage
       
        }
        else {
            openModals();
          }
        
    };
    
    

    const [isReadMore, setIsReadMore] = useState(true);

      const toggleDescription = () => {
    
        // const text = document.getElementById('disc');
        // text.classList.remove('description');
        // text.classList.add('disc1');
        setIsReadMore(!isReadMore);
        
      };

      
  const product = productData?.data?.[0];
  const imageUrl = `https://api.arivomkadai.com/product_data/${product?.image}` || "https://arivomkadai.com/favicon.png";
  const productTitle = product?.product || "ArivomKadai - We Make Your Shopping Simple";
  const productDescription = product?.content || "ArivomKadai - We Make Your Shopping Simple";

  return (
       <>
  <HelmetProvider>   
    <Helmet>
      <title>{productTitle}</title>
      <link rel="canonical" href={`https://arivomkadai.com/offlineproduct?id=${productId}/`} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={productTitle} />
      <meta property="og:description" content={productDescription} />
      <meta property="og:url" content={`https://arivomkadai.com/offlineproduct?id=${productId}/`} />
      <meta property="og:site_name" content="ArivomKadai" />
      <meta property="og:updated_time" content="2024-08-17T13:12:25+00:00" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:secure_url" content={imageUrl} />
      <meta property="og:image:alt" content={productTitle} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="article:published_time" content="2024-08-17T13:12:25+00:00" />
      <meta property="article:modified_time" content="2024-08-17T13:12:25+00:00" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={productTitle} />
      <meta name="twitter:description" content={productDescription} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:label1" content="Written by" />
      <meta name="twitter:data1" content="ArivomKadai" />
      <meta name="twitter:label2" content="Time to read" />
      <meta name="twitter:data2" content="2 minutes" />
    </Helmet>
    </HelmetProvider>  


    <div class="sidebars">
    <a  className="sidebars-on" href="/online-products">
	    <h3 class="bar-content">online Products </h3>
	  </a>
  </div>
  <div class="main-bar">

	<Header  />

    <main class="main mt-3 d-mt-10">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a>
                    <span></span>
                      {productData && ( productData.data[0].product )}
                </div>
            </div>
        </div>


        <div class="modal fade custom-modal" id="copyModalsready" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
         <div class="modal-dialog" style={{maxWidth: '35%'}}>
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalsalready}></button>
              <div class="modal-body bg-remover">
                <div class="login_wrap widget-taber-content background-white">
                  <div class="padding_eight_all bg-white" align="center">
                    <div class="heading_s1">
                      
                        <p class="mb-30">{productData && ( productData.data[0].product )}is already in cart</p>
                        <button className="button mr-10 mt-10" onClick={closeModalsalready}>Cancel</button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="modal fade custom-modal" id="copyModalspreorder" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
         <div class="modal-dialog" style={{maxWidth: '35%'}}>
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalspreorder}></button>
              <div class="modal-body bg-remover">
                <div class="login_wrap widget-taber-content background-white">
                  <div class="padding_eight_all bg-white" align="center">
                    <div class="heading_s1">
                      
                        <p class="mb-30">Insufficent Stock {productData && ( productData.data[0].product )}</p>
                        <button className="button mr-10 mt-10" onClick={closeModalspreorder}>Cancel</button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="modal fade custom-modal" id="copyModalstock" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
         <div class="modal-dialog" style={{maxWidth: '35%'}}>
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalstock}></button>
              <div class="modal-body bg-remover">
                <div class="login_wrap widget-taber-content background-white">
                  <div class="padding_eight_all bg-white" align="center">
                    <div class="heading_s1">
                      <h4 class="mb-30">Out of Stock</h4>
                        {/* <p class="mb-30">Currently, this product is out of stock. Our executive will contact you within 24 hours after placing your order to discuss the delivery time.</p> */}
                        <p class="mb-30">Available stock is <span>{stock}</span>  If you wish, Kindly preorder the balance quantity after completing this order.</p>
                    
                    </div>
                    <button className="button mr-10 mt-10" onClick={Continuestock}>Add to Cart</button>
                    <button className="button mr-10 mt-10" onClick={closeModalstock}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade custom-modal" id="copyModal1" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
         <div class="modal-dialog" style={{maxWidth: '35%'}}>
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal1}></button>
              <div class="modal-body bg-remover">
                <div class="login_wrap widget-taber-content background-white">
                  <div class="padding_eight_all bg-white" align="center">
                    <div class="heading_s1">
                      <h4 class="mb-30">Out of Stock</h4>
                        <p class="mb-30">Currently, this product is out of stock. Our executive will contact you within 24 hours after placing your order to discuss the delivery time.</p>
                        
                    
                    </div>
                    <button className="button mr-10 mt-10" onClick={contPreOrder}>Add to Cart</button>
                    <button className="button mr-10 mt-10" onClick={closeModal1}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal1 fade custom-modal" id="copyModals" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog" style={{maxWidth: '35%'}}>
                                                        <div class="modal-content">
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                                                            <div class="modal-body bg-remover">
                                                                <div class="login_wrap widget-taber-content background-white">
                                                                <div class="padding_eight_all bg-white" align="center">
                                                                        <div class="heading_s1">
                                                                            <h4 class="mb-5">Warning</h4>
                                                                            <p class="mb-30">Your cart contains items from Regular Products. Would you like to reset your cart for adding items from Pre-Order Products?</p>
                                                                        </div>
                                                                        <button className="button mr-10 mt-10" onClick={handleYesClick}><i className="fa-regular fa-copy"></i> Yes</button>
                                                                        <button className="button mr-10 mt-10" onClick={handleNoClick}><i className="fa-regular fa-copy"></i> No</button>
                             
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
        </div>




        <div class="container mb-30">
            <div class="row">
                <div class="col-xl-12 col-lg-12 m-auto">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="product-detail accordion-detail">
                                <div class="row mb-50 mt-30">
                                    <div class="col-md-4 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                                        <div class="detail-gallery">
                                        <span class="zoom-icon" onClick={openModal}><i class="fi-rs-share"></i></span>
                                            <div class="modal1 fade custom-modal" id="copyModal" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog" style={{maxWidth: '35%'}}>
                                                        <div class="modal-content">
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                                                            <div class="modal-body bg-remover">
                                                                <div class="login_wrap widget-taber-content background-white">
                                                                <div class="padding_eight_all bg-white">
                                                                        <div class="heading_s1">
                                                                            <h4 class="mb-5">Share</h4>
                                                                            <p class="mb-30">Let's talk about New Shopping Experience.</p>
                                                                        </div>
                                                                        <a class="button mr-10 mt-10" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Farivomkadai.com%2Fproduct%3Fid%3D${productId}`} target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
                                                                        <a class="button mr-10 mt-10" href={`https://twitter.com/intent/tweet?url=https%3A%2F%2Farivomkadai.com%2Fproduct%3Fid%3D${productId}`} target="_blank"><i class="fa-brands fa-twitter"></i></a>
                                                                        <a class="button mr-10 mt-10" href={`https://api.whatsapp.com/send?text=Check%20out%20this%20awesome%20Product%21%20https%3A%2F%2Farivomkadai.com%2Fproduct%3Fid%3D${productId}`} target="_blank"><i class="fa-brands fa-whatsapp"></i></a>

                                                                        <button class="button mr-10 mt-10" onClick={() => copyButtonClick(`https://arivomkadai.com/offlineproduct?id=${productId}`)}><i class="fa-regular fa-copy"></i> Copy</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div class="product-image-slider" style={{contentVisibility:'visible'}}>
                                                <figure class="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image !== '' && (<>
                                                    {productData && (  
                                                       <img src={`https://api.arivomkadai.com/product_data/${productData.data[0].image}`}  alt={productData.data[0].p_id} width='100%' /> 
                                                    )}
                                                     </>
                                                    )}
                                                </figure>
                                                <figure class="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image1 !== 'no_image.webp' &&  (<>
                                                   {productData && (  
                                                       <img src={`https://api.arivomkadai.com/product_data/${productData.data[0].image1}`} alt={productData.data[0].p_id} width='100%' /> 
                                                    )}
                                                    </>
                                                    )}
                                                </figure>
                                                <figure class="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image2 !== 'no_image.webp' && (<>
                                                    {productData && (  
                                                       <img src={`https://api.arivomkadai.com/product_data/${productData.data[0].image2}`} alt={productData.data[0].p_id} width='100%' /> 
                                                    )}
                                                    </>
                                                    )}
                                                </figure>
                                                <figure class="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image3 !== 'no_image.webp' && (<>
                                                    {productData && (  
                                                       <img src={`https://api.arivomkadai.com/product_data/${productData.data[0].image3}`} alt={productData.data[0].p_id} width='100%' /> 
                                                    )}
                                                    </>
                                                    )}
                                                </figure>
                                                <figure className="border-radius-10">
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].video !== '' && (
  <>
    
      {productData.data[0].video.endsWith('.mp4') ? (
        <video src={`https://api.arivomkadai.com/product_data/${productData.data[0].video}`}  width="100%" autoPlay loop muted controls
        ></video>
      ) : (
        <iframe width="100%" height="560" src={`https://www.youtube.com/embed/${productData.data[0].video}?autoplay=1&loop=1&mute=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
        ></iframe>
      )}
    
  </>
)}
</figure>

                                                  
                                              
                                            </div>
                                  
                                            <div class="slider-nav-thumbnails">
                                                <div>
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image !== '' && (<>
                                                    {productData && (  
                                                       <img src={`https://api.arivomkadai.com/product_data/${productData.data[0].image}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                     </>)}
                                                </div>
                                                <div>
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image1 !== 'no_image.webp' && (<>
                                                    {productData && (  
                                                       <img src={`https://api.arivomkadai.com/product_data/${productData.data[0].image1}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                     </>)}
                                                </div>
                                                <div>
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image2 !== 'no_image.webp' && (<>
                                                    {productData && (  
                                                       <img src={`https://api.arivomkadai.com/product_data/${productData.data[0].image2}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                     </>)}
                                                </div>
                                                <div>
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].image3 !== 'no_image.webp' && (<>
                                                  {productData && (  
                                                       <img src={`https://api.arivomkadai.com/product_data/${productData.data[0].image3}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                </>)}
                                                    
                                                 
                                                </div>
                                                <div>
                                                {productData && productData.data && productData.data.length > 0 && productData.data[0].video !== '' && (
  <>
    
      {productData.data[0].video.endsWith('.mp4') ? (
        <video src={`https://api.arivomkadai.com/product_data/${productData.data[0].video}`}  width="100%" autoPlay loop muted controls
        ></video>
      ) : (
        <iframe width="100%" height="60" src={`https://www.youtube.com/embed/${productData.data[0].video}?autoplay=1&loop=1&mute=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
        ></iframe>
      )}
    
  </>
)}
                                                    </div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                    <div class="col-md-8 col-sm-12 col-xs-12">
                                        <div class="detail-info pr-30 pl-30">
                                            Product Code : <span class="stock-status out-stock"> {productData && ( productData.data[0].product_code )}</span>
                                            <h2 class="title-detail">{productData && ( productData.data[0].product )}</h2>
                                            <div class="short-desc mb-30">
                                            <p className="font-lg description"  id="disc">
                                              
                                                      {productData && (
                                                <span>
                                                      {isReadMore
                                                        ?  <ContentEditable html={`${productData.data[0].content.slice(0, 200)}`} />
                                                        :  <ContentEditable html={`${productData.data[0].content}`}/>}
                                                      {productData.data[0].content.length > 200 && (
                                                        <button id="readMoreBtn" onClick={toggleDescription}>
                                                          {isReadMore ? '...read more' : ' show less'}
                                                        </button>
                                                      )}
                                                </span>
                                                )}
                                            </p>
                                             </div>
                                           
                                            


                                            {productData && ( productData.data[0].variations) ?(<>

                                                <div class="clearfix product-price-cover">
                                                <div class="product-price primary-color float-left">
                                                    <span class="current-price text-brand">₹ {selectedPrice} <del class="text-dark" style={{fontSize:'28px'}}>₹ {selectedMrp}</del></span>
                                                </div>
                                            </div>

                                            
                                                <div class="attr-detail attr-size mb-30">
                                                <strong class="mr-10">Size / Weight: </strong>
                                                 <ul class="list-filter size-filter font-small">
                                                    {productData && productData.data[0].variations.map((variations) => (
                                                      <li className={selectedSize === variations.value ? 'active' : ''} > <a
                                                     
                                                      onClick={() => handleSizeClick(variations.value)}
                                                  >{variations.value+' '+variations.weights}</a></li>
                                                    ))}
                                                   </ul>
                                                  
                                            </div>
                                               </>):(<>

                                                <div class="clearfix product-price-cover">
                                                <div class="product-price primary-color float-left">
                                                    <span class="current-price text-brand">₹ {productData && ( productData.data[0].price )}</span>
                                                </div>
                                            </div>

                                               </>)} 
                                               
                                           

                                            <div className="detail-extralink mb-50 mb-51" align="center" >
                                            <button className="btn1" onClick={handleDecrement}>-</button>
                                           
      <div className="detail-qty border radius">
        
     
      <input
        type="text"
        name="quantity"
        className="qty-val"
        value={quantity}
        min="1"
      onChange={handleQuantityChange}
      />
      </div>
      <button className="btn1"  onClick={handleIncrement}>+ </button>
      <div className="product-extra-link2">
      {productData && ( productData.data[0].stock <= 0 ?(
        <>
        {productData && ( productData.data[0].preorder === '1' ?(<>
          <button onClick={() => openmodalpreorder(productData && ( productData.data[0].p_id))} className="button button-add-to-cart">
         <i className="fi-rs-shopping-cart"></i>Pre Order
       </button></>):
        (<>
        <button onClick={() => openModal1(productData && ( productData.data[0].p_id))} className="button button-add-to-cart">
         <i className="fi-rs-shopping-cart"></i>Pre Order
       </button>
        </>))}
         
       </>
      ):(<>
        <button onClick={addToCart} className="button button-add-to-cart">
          <i className="fi-rs-shopping-cart"></i>Add to cart
        </button>
        </>
      ) )}
        <button onClick={addTowish} class="button button-add-to-cart ml-5"><i class="fi-rs-heart"></i>Wishlist</button>
                                            
      </div>
    </div>
                                           
                                        </div>
                                     
                                    </div>
                                </div>
                                
                                <div class="row mt-60">
                                    <div class="col-12">
                                        <h2 class="section-title style-1 mb-30">Related products</h2>
                                    </div>
                                    <div class="col-12">
                                        <div class="row related-products">

                                        {categories && Array.isArray(categories) && categories.map(product => (
                                         product.variations.length > 0 ? (
                                            <div class="col-xl-2 col-lg-3 col-md-3 col-12 col-sm-4">
                                                <div class="product-cart-wrap hover-up">
                                                    <div class="product-img-action-wrap">
                                                        <div class="product-img product-img-zoom ">
                                                            <a href={`offlineproduct?id=${product.p_id}`} tabindex='0'>
                                                                <img class="default-img" src={`https://api.arivomkadai.com/product_data/${product.image}`} alt={product.product} />
                                                                <img class="hover-img" src={`https://api.arivomkadai.com/product_data/${product.image}`} alt={product.product} />
                                                            </a>
                                                        </div>
                                                       
                                                    </div>
                                                    <div class="product-content-wrap">
                                                        <h2><a href={`offlineproduct?id=${product.p_id}`} tabindex='0'>{product.product.length > 50 ? `${product.product.slice(0, 50)}...` : product.product}</a></h2>
                                                      
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        ):""
                                        ))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </main>

    <Footeroffline />

    </div>

    
    </>
    );
}

export default Offlineproduct;