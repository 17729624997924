import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';

ReactSession.setStoreType("localStorage");

function Invoice() {
    const orderId = sessionStorage.getItem('id');
    const [preorder, setpreOrder]=useState({});
    const [order, setorder] = useState([]);
    const [preorderorder, setpreorderorder] = useState([]);
  
    const fetchData = async () => {
      try {
         const response = await axios.post('https://api.arivomkadai.com/processsucess/' , {id:orderId});
         setorder(response.data.data[0]);
         console.log(response.data.data[0]);
      } catch (error) {
         console.error("Error fetching data:", error);
      }
    };

    const fetchpreorderData = async () => {
      try {
        const formData = {id:orderId};
        const response = await axios.post(`https://api.arivomkadai.com/proordersucess/`, formData);
        setpreorderorder(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    const fetchpreDatas = async () => {
      try {
        const response = await axios.get('https://api.arivomkadai.com/preorder_orders/');
        setpreOrder(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };


    useEffect(() => {
      fetchpreDatas();
      fetchpreorderData();
      fetchData();
    }, []);
  
    //const order = order && order.data && order.data.filter(item => item.order_id === orderId); // Filter product data based on the category ID
    //console.log("Filtered orders:", order);

    //const preorder = preorder && preorder.data && preorder.data.filter(item => item.order_id === orderId); // Filter product data based on the category ID
    //cnsole.log("Filtered preorders:", preorder);
    
    let gst = 0;    
    let price = 0;    
    let subTotal = 0;    

    preorderorder && preorderorder.data && preorderorder.data.preorder === 'preorder' ?
    preorderorder && preorderorder.data && preorderorder.data.orderdetails && preorderorder.data.orderdetails.forEach(product => {
       
       price += Number(product.price*product.quantity);
    })

    : order?.orderData?.forEach(product => {
     
      price += Number(product.price*product.quantity);
      gst += Number(((product.price-(product.price/(1+product.gst/100)))*product.quantity));
      subTotal += (Number(product.price*product.quantity)-(Number(product.price*product.quantity)*(product.gst/(product.gst+100)))).toFixed(2);
    })

    gst = (gst).toFixed(2);
    subTotal = (price-gst).toFixed(2);

  return (
   
    <div  id="pdf-content" class="hide" style={{minWidth: '1000px', maxWidth: '1000px', margin:'0px'}}>        
      
      
      <div style={{ display: 'flex'}}>
        <div  style={{ minWidth: '9%',  minHeight: '1440px', background: '#ffc107', }}></div>
        <div style={{minWidth: '87%', maxWidth: '91%', marginLeft: 'auto', marginRight: 'auto', marginTop: '50px'}}>
        <table style={{borderCollapse: 'collapse', top: '50px', width:'100%'}}>
    
    <tr>
        <td colspan="3" style={{border:'0px',  textAign:'left', fontsize:'12px'}}>
        <img src="logo.png" alt="logo"/>
      </td>
        <td width="50%" colspan="4" style={{border:'0px', textAlign:'right', fontSize:'15px', paddingbottom:'20px'}}>
        5D, R.P jaya paradise, Chitra Nagar<br></br> Opposite to Amman kovil, Saravanampatti<br></br>Coimbatore – 641 035<br></br>
              Ph : +91 9043842020 <br></br> Email: arivomkadaioffical@gmail.com<br></br>
      </td>
      </tr>
    <tr>
    <td colspan="7"  style={{border: '1px solid #ddd', textAlign: 'left', fontSize: '16px',  background: '#3bb77e', padding: '20px', color: '#fff' }}>
    {preorderorder && preorderorder.data && preorderorder.data.preorder === 'preorder' ? (
  <>
    {preorder && preorder.length > 0 ? (
      <>
        Invoice: #000{preorder.id}<br />
        Order: {preorder.order_id}<br />
        Date: {preorder.date}
      </>
    ) : (
      <>
        No preorder found
      </>
    )}
  </>
) : (
  <>
        Invoice: #000{order && order.invoiceNumber}<br />
        Order: {order && order.order_id}<br />
        Date: {order && order.date}
  </>
)}

       
    </td>
    </tr>
    <tr>
        <th style={{padding: '10px'}}>
        </th>
    </tr>
    <tr>
    <th colSpan={3} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e', color:'#fff' }}>
    Billing Address
  </th>
  <th colSpan={4} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e', color:'#fff' }}>
    Shipping Address
  </th>
  
    </tr>	
    
  
        <tr>
        <td colspan="3"  style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>
        {preorderorder && preorderorder.data && preorderorder.data.preorder === 'preorder' ? (
  <>
    {preorder && preorder.length > 0 ? (
      <>
        {preorder.customerData[0].name}<br></br>
        {preorder.customerData[0].buiulding} {preorder.customerData[0].street}<br></br> {preorder.customerData[0].city}, {preorder.customerData[0].state}, {preorder.customerData[0].pincode}<br></br>
        {preorder.customerData[0].email}<br></br>
              Ph :  {preorder.customerData[0].number}
      </>
    ) : (
      <>
        No preorder found
      </>
    )}
  </>
) : (
  <>
   {order?.customerData && <>
     {order?.customerData[0]?.name}<br></br>
     {order?.customerData[0]?.buiulding} {order?.customerData[0]?.street}<br></br> {order?.customerData[0]?.city}, {order?.customerData[0]?.state}, {order?.customerData[0]?.pincode}<br></br>
     {order?.customerData[0]?.email}<br></br>
      Ph :  {order?.customerData[0]?.number}
      </>
   }
  </>
)}
         
      </td>
        <td colspan="4"  style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>
        {preorderorder && preorderorder.data && preorderorder.data.preorder === 'preorder' ? (
  <>
    {preorder && preorder.length > 0 ? (
      <>
        {preorder.name}<br></br>
        {preorder.buiulding} {preorder.street}<br></br> {preorder.city}, {preorder.state}, {preorder.pincode}<br></br>
        Landmark :  {preorder.landmark}<br></br>
        {preorder.email}<br></br>
              Ph :  {preorder.number}
      </>
    ) : (
      <>
        No preorder found
      </>
    )}
  </>
) : (
  <>
   
     {order.name}<br></br>
     {order.buiulding} {order.street}<br></br> {order.city}, {order.state}, {order.pincode}<br></br>
     Landmark :  {order.landmark}<br></br>
     {order.email}<br></br>
     Ph :  {order.number}
  </>
)}
      </td>
      </tr>
    <tr>
    <th colSpan="3" style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e', color:'#fff'}}>Payment Details</th>
        <th colspan="34"  style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e', color:'#fff'}}>Shipping Details</th>
      </tr>
    <tr>
        <td colspan="3"  style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>Payment Mode : Online <br></br>
        {preorderorder && preorderorder.data && preorderorder.data.preorder === 'preorder' ? (
  <>
    {preorder && preorder.length > 0 ? (
      <>
       <small>Ref ID : {preorder.payment_id}</small>
      </>
    ) : (
      <>
        No preorder found
      </>
    )}
  </>
) : (
  <>
    <small>Ref ID : {order.payment_id}</small>
  </>
)}
        </td>
        <td colspan="4"  style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>Shipping & Handling - Flat Rate <br></br>
        {preorderorder && preorderorder.data && preorderorder.data.preorder === 'preorder' ? (
  <>
    {preorder && preorder.length > 0 ? (
      <>
       (Total Shipping Charges ₹ {preorder.shipping}.00)
      </>
    ) : (
      <>
        No preorder found
      </>
    )}
  </>
) : (
  <>
    Total Shipping Charges ₹ {Number(order.shipping).toFixed(2)}
  </>
)}
      
        </td>
      </tr>
    <tr>
        <th style={{padding: '10px'}}>
        </th>
    </tr>
    <tr>
        <th  style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e', color:'#fff'}}>Products</th>
        <th width='10%'style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e', color:'#fff'}}>SKU</th>
        <th width='10%'style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e', color:'#fff'}}>HSN Code</th>
        <th width='15%' style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e', color:'#fff'}}>Price</th>
        <th width='15%' style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e', color:'#fff'}}>Tax</th>
        <th width='5%' style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e', color:'#fff'}}>Qty</th>
        <th width='15%' style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e', color:'#fff'}}>Sub Total</th>
    </tr>
    
    {preorderorder && preorderorder.data && preorderorder.data.preorder === 'preorder'?(<>
          
          {preorderorder && preorderorder.data && preorderorder.data.orderdetails && preorderorder.data.orderdetails.map(product => (
    <tr key={product.id}>
    <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.product}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.code}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.hsn}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>₹ {Number(product.price-(product.price-(product.price/(1+product.gst/100)))).toFixed(2)} </td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>₹ {(product.price-(product.price/(1+product.gst/100))).toFixed(2)} - {product.gst}% </td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.quantity}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'right', padding: '10px', fontSize: '15px'}}>₹ {Number(product.subtotal).toFixed(2)}</td>
</tr>
   ))}
    
    
  
    
    <tr>
        <th style={{padding: '10px'}}>
        </th>
    </tr>
    
    <tr>
        <td colspan="6" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Sub Total</td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {subTotal}</td>
    </tr>
    
    <tr>
        <td colspan="6" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Tax <small>GST</small></td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {gst}</td>
    </tr>
    
    <tr>
        <td colspan="6" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Shipping Charges</td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {preorderorder && preorderorder.data && preorderorder.data.shipping}.00</td>
    </tr>
    
    <tr>
        <th colspan="6" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Grand Total</th>
        <th style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {preorderorder && preorderorder.data && preorderorder.data.grandtotal || 0}.00</th>
    </tr>
    
    <tr>
        <th colspan="7" tyle={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px', minHeight: '30px'}}>Note</th>
        
    </tr>
    </>)
    :
        (<>
        {order && order.orderData && order.orderData.map(product => (
         <tr key={product.id}>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.product}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.code}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.hsn}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>₹ {Number(product.price/(1+product.gst/100)).toFixed(2)}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>₹ {(product.price-(product.price/(1+product.gst/100))).toFixed(2)} - {product.gst}%</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.quantity}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'right', padding: '10px', fontSize: '15px'}}>₹ {Number(product.price * product.quantity).toFixed(2)}</td>
    </tr>
   ))}
    
    
  
    
    
    
    <tr>
        <td colspan="6" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Sub Total</td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {subTotal}</td>
    </tr>
    
    <tr>
        <td colspan="6" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Tax <small>GST</small></td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {gst}</td>
    </tr>
    
    <tr>
        <td colspan="6" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Shipping Charges</td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {Number(order?.shipping).toFixed(2)}</td>
    </tr>
    
    <tr>
        <th colspan="6" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Grand Total</th>
        <th style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹{Number(order?.grand_total).toFixed(2)}</th>
    </tr>
    
    <tr>
        <th colspan="7" tyle={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px', minHeight: '30px'}}>Note</th>
        
    </tr>
       </>
       )}
      </table>

        </div>
      </div>




    
    
  
    </div>
   

  )
}
export default  Invoice;
